import React from "react"
import CalculateSpacing from "../functionCalculateSpacing"

const Header = ({ content, spacing, align = 'left', type = 'h1' }) => {
  let text = content[0].item;
  let paddingBottom = CalculateSpacing(spacing);

  switch(type) {

    case 'h1': {
      return (
        <div className={"container"}>
          <h1 className={paddingBottom} style={{textAlign: align}}>
            {text}
          </h1>
        </div>
      )
      }

    case 'h2': {
        return (
          <div className={"container"}>
            <h2 className={paddingBottom} style={{textAlign: align}}>
              {text}
            </h2>
          </div>
        )
    }

    case 'h3': {
    return (
      <div className={"container"}>
        <h3 className={paddingBottom} style={{textAlign: align}}>
          {text}
        </h3>
      </div>
    )
  }
}
}

export default Header
