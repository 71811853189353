import React, { useEffect } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { textAppearAnimationAll } from "../components/textAppearAnimation"
//import HeaderVideo from "../components/HeaderVideo"
import VideoSection9Videos from "../components/works/VideoSection9Videos"
import VideoSectionFullScreen from "../components/works/VideoSectionFullScreen"
import VideoSectionHalfScreen from "../components/works/VideoSectionHalfScreen"
import TextSection from "../components/works/TextSection"
import Header from "../components/works/Header"
import VideoSection3Videos from "../components/works/VideoSection3Videos"
import VideoSectionWithPadding from "../components/works/VideoSectionWithPadding"
import ClicableVideoSection from "../components/works/clicableVideoSection"
import Faqs from "../components/works/Faqs"
//import AniLink from "gatsby-plugin-transition-link/AniLink"

//import Img from "gatsby-image"
import PictureSection from "../components/works/PictureSection"

const TypeOfWorkPostTemplate = ({ data: { post } }) => {

  // let gallery = false; //post.customFieldsWork?.videoGallery || false
  // let services = post.customFieldsWork?.services || false
  // let credits = post.customFieldsWork?.credits || false

  useEffect(() => {
    textAppearAnimationAll("h1")
  })

  return (
    <Layout page={" global-wrapper"}>
      <Seo title={post.seoSettings.title} description={post.seoSettings.description} extendedTitle={false} />


      <div className="container">
        <div className={"hero-section"}>
          <div className="hero-section-title">
            <h1 className={"padding-bottom-64"}>
              {parse(post.title)}
            </h1>
          </div>
        </div>
      </div>

      {!!post.content && (
      <div className={"container"}>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >

          <div className={"section-overview section-up"}>
            
            <div className={"padding-bottom-112"}>
              {!!post.content && (
                <section itemProp="articleBody">{parse(post.content)}</section>
              )}
            </div>
          </div>
        </article>
      </div>)}
      {post.customFieldsWorkLayout.block?.map(({type, spacing, items, configuration}) => {
        let element = null;
        switch(type)
        {
          case '9_video': {
            element = <VideoSection9Videos videos={items}  spacing={spacing} configuration={configuration || []} />
            break;
          }

          case '3_video': {
            element = <VideoSection3Videos videos={items}  spacing={spacing} configuration={configuration || []}/>
            break;
          }

          case '2_video': {
            element = <VideoSectionHalfScreen videos={items}  spacing={spacing}  configuration={configuration || []} />
            break;
          }

          case '2_video_nospacing': {
            element = <VideoSectionHalfScreen videos={items}  spacing={spacing} />
            break;
          }

          case '1_video': {
            if(configuration?.includes('clicable')) {
              element = <ClicableVideoSection videos={items} spacing={spacing} configuration={configuration || []} />
            } else {
              element = <VideoSectionFullScreen videos={items} spacing={spacing} configuration={configuration || []} />
            }
            break;
          }

          case 'centered_video': {
            element = <VideoSectionWithPadding videos={items} spacing={spacing} />
            break;
          }

          case 'text': {
            element = <TextSection content={items} spacing={spacing} />
            break;
          }

          case 'clickable_video': {
            element = <VideoSectionFullScreen videos={items} spacing={spacing} autoplay={true} />
            break;
          }

          case 'picture': {
            element = <PictureSection content={items} spacing={spacing} configuration={configuration || []} />
            break;
          }

          case 'h1': {
            element = <Header content={items} spacing={spacing} type="h1" />
            break;
          }

          case 'h2': {
            element = <Header content={items} spacing={spacing} type="h2" />
            break;
          }

          case 'h3': {
            element = <Header content={items} spacing={spacing} type="h3" />
            break;
          }

          case 'rich_text': {
            element = <TextSection content={items} spacing={spacing} rich="1"/>
            break;
          }

          case 'faqs': {
            element = <Faqs content={items} spacing={spacing} />
            break;
          }

          default: {

          }
        }
        return (<>
          {element}
        </>)
      })}

    </Layout>
  )
}

export default TypeOfWorkPostTemplate

export const pageQuery = graphql`
    query TypeOfWorkPostById(
        # these variables are passed in via createPage.pageContext in gatsby-node.js
        $id: String!

    ) {
        # selecting the current post by id
        post: wpTypeOfWork(id: { eq: $id }) {
            id
            content
            title
            date(formatString: "MMMM DD, YYYY")

            customFieldsWorkLayout {
                headerVideo
                block {
                    items {
                        item
                        richText
                        picture {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    spacing
                    type
                    configuration
                }
            }

            seoSettings {
              title
              description
            }

            
        }




    }
`