import React from "react"
import HeaderVideo from "../HeaderVideo"
import CalculateSpacing from "../functionCalculateSpacing"

const VideoSectionWithPadding = ({ videos, spacing }) => {

  if(videos.length < 1){
    console.log('Missing video');
    return (<></>);
  }

  const video = videos[0].item

  if(!video){
    console.log('Missing video');
    return (<></>);
  }

  let padding = CalculateSpacing(spacing);

  return (
    <div className={padding}>
      <div className={"container"}>
        <HeaderVideo video={video} padding={""} />
      </div>
    </div>
  )
}

export default VideoSectionWithPadding
