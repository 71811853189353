import React from "react"
import HeaderVideo from "../HeaderVideo"
import CalculateSpacing from "../functionCalculateSpacing"

const VideoSectionHalfScreen = ({ videos, spacing, configuration = [] }) => {

  if(videos.length < 2){
    console.log('Block expected 2 videos');
    return (<></>);
  }

  const left = videos[0].item
  const right = videos[1].item

  if(!left || !right){
    console.log('Block expected 2 videos');
    return (<></>);
  }

  let padding = CalculateSpacing(spacing);

  const gap = configuration?.includes('gap');

  if(configuration.includes('centered')) {
    padding += ' container';
  }

  return (
    <div className={padding}>
      <div className={gap ? 'video-section-half gap video-section' : 'video-section-half video-section'}>
        <div className={"video-section-half-left"}>
          <HeaderVideo video={left}
                       padding={""}
                       wide={configuration?.includes('wide')}
                       square={configuration?.includes('square')}
          />
        </div>
        <div className={"video-section-half-right"}>
          <HeaderVideo video={right}
                       padding={""}
                       wide={configuration?.includes('wide')}
                       square={configuration?.includes('square')}
          />
        </div>
      </div>
    </div>
  )
}

export default VideoSectionHalfScreen
