import React from "react"
import HeaderVideo from "../HeaderVideo"
import CalculateSpacing from "../functionCalculateSpacing"

const VideoSectionFullScreen = ({ videos, spacing, autoplay = true , configuration = []}) => {

  if(videos.length < 1){
    console.log('Missing video');
    return (<></>);
  }

  const video = videos[0].item

  if(!video){
    console.log('Missing video');
    return (<></>);
  }

  let paddingBottom = CalculateSpacing(spacing);
  let centered = configuration.includes("centered");

  return (
    <div className={centered ? 'container' : ''}>
      <HeaderVideo video={video}
                   padding={paddingBottom}
                   autoplay={autoplay}
                   wide={configuration?.includes('wide')}
                   square={configuration?.includes('square')}
      />
    </div>
  )
}

export default VideoSectionFullScreen
