import React, { useState } from "react"
import CalculateSpacing from "../functionCalculateSpacing"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';

const Faqs = ({ content, spacing, align = 'left' }) => {

    let paddingBottom = CalculateSpacing(spacing);
    
    return (
        <div className="container">
            <div className={paddingBottom} style={{textAlign: align}}>
                <Accordion allowZeroExpanded="true">
                    {content.map((value) => {
                        return (
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    <h3>{value.item}</h3>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className={paddingBottom} style={{textAlign: align}} dangerouslySetInnerHTML={{__html: value.richText}} />
                                </AccordionItemPanel>
                            </AccordionItem>
                        )
                    })}
                </Accordion>
            </div>
        </div>
    )
}

export default Faqs