import React from "react"
import CalculateSpacing from "../functionCalculateSpacing"

const TextSection = ({ content, spacing, align = 'left', rich = 0 }) => {
  let text = content[0].item;
  let paddingBottom = CalculateSpacing(spacing);
  let richText = content[0].richText;

  if (rich==1) {
    return (
      <div className={"container"}>
        <div className={paddingBottom} style={{textAlign: align}} dangerouslySetInnerHTML={{__html: richText}} />
      </div>
    )
  }

  else
  return (
    <div className={"container"}>
      <div className={paddingBottom} style={{textAlign: align}}>
        {text}
      </div>
    </div>
  )
}

export default TextSection
