import React from "react"

const HeaderVideo = ({ video, padding = 'padding-bottom-112', autoplay = true, wide = false, square = false}) => {

  let videoClass = 'full-page-video-section';
  if(wide) videoClass += ' wide';
  if(square) videoClass += ' square';

  return (
    <div className={padding}>
      <div className={videoClass}>
        <div className={"vimeo-embed"}>
          <iframe id={"vimeo-video-embed"}
                  title={"vimeo player"}
                  src={"https://player.vimeo.com/video/" + video + "?autoplay=" + (autoplay ? 1 : 0) + "&muted=1&loop=1&autopause=0&title=0&playsinline=1&portrait=1&controls=0"}
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                  frameBorder="0"
                  webkitallowfullscreen={"true"} mozallowfullscreen={"true"} allowFullScreen
                  allow="autoplay; fullscreen"
          />
        </div>
      </div>
    </div>
  )
}

export default HeaderVideo
