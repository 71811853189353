import React from "react"
import CalculateSpacing from "../functionCalculateSpacing"
import Img from "gatsby-image"

const PictureSection = ({ content, spacing, configuration}) => {

  let fluid = content[0].picture?.localFile.childImageSharp.fluid
  let padding = CalculateSpacing(spacing);
  if(configuration.includes('centered')) {
    padding += ' container';
  }

  if(!fluid){
    return <></>
  }

  return (
    <div className={padding}>
      <Img
        fluid={fluid}
      />
    </div>
  )
}

export default PictureSection
