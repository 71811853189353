import React from "react"
import HeaderVideo from "../HeaderVideo"
import CalculateSpacing from "../functionCalculateSpacing"

const VideoSection3Videos = ({ videos, spacing, configuration = [] }) => {

  let padding = CalculateSpacing(spacing);
  const gap = configuration?.includes('gap');

  if(configuration.includes('centered')) {
    padding += ' container';
  }

  return (
    <div className={padding}>
      <div className={gap ? 'video-section-3videos gap video-section' : 'video-section-3videos video-section'}>
        {videos.map(( { item } ) => {
          return (
            <HeaderVideo
              video={item}
              padding={""}
              key={item}
              wide={configuration?.includes('wide')}
              square={configuration?.includes('square')}
            />
          )
        })}
      </div>
    </div>
  )
}

export default VideoSection3Videos
